import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/logo.png"
import { AppDispatch, RootState } from "../../store";
import { getSettings } from "../../features/settingsSlice";
import { useEffect } from "react";

const Footer = () => {
    const dispatch = useDispatch<AppDispatch>();
    const settings = useSelector((state: RootState) => state.settings.settings);
    
    useEffect(() => {
        dispatch(getSettings())
        
    }, [dispatch])
    return (
        <footer>
            <div className="container">
                <div className="text">
                    <img src={logo} alt="" />
                    <p>
                    {settings?.footer_description as any || ""}
                    </p>
                        <br />
                    <p>
                    <b style={{marginRight: 4}}>
                    TRIO 
                    </b>
                         All Rights Reserved.
                    </p>
                </div>
                <div className="socials">
                    {
                        settings?.phone && (
                            <a href={"tel:" + settings.phone}>
                                <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5196 0.557129H19.4309C8.69952 0.557129 0 9.25921 0 19.9938V20.0825C0 30.817 8.69952 39.5191 19.4309 39.5191H19.5196C30.251 39.5191 38.9505 30.817 38.9505 20.0825V19.9938C38.9505 9.25921 30.251 0.557129 19.5196 0.557129Z" fill="#FF512F"/>
                                    <path d="M13.62 18.3471C15.06 21.1771 17.38 23.4971 20.21 24.9371L22.41 22.7371C22.69 22.4571 23.08 22.3771 23.43 22.4871C24.55 22.8571 25.75 23.0571 27 23.0571C27.2652 23.0571 27.5196 23.1625 27.7071 23.35C27.8946 23.5376 28 23.7919 28 24.0571V27.5571C28 27.8223 27.8946 28.0767 27.7071 28.2642C27.5196 28.4518 27.2652 28.5571 27 28.5571C22.4913 28.5571 18.1673 26.7661 14.9792 23.5779C11.7911 20.3898 10 16.0658 10 11.5571C10 11.2919 10.1054 11.0376 10.2929 10.85C10.4804 10.6625 10.7348 10.5571 11 10.5571H14.5C14.7652 10.5571 15.0196 10.6625 15.2071 10.85C15.3946 11.0376 15.5 11.2919 15.5 11.5571C15.5 12.8071 15.7 14.0071 16.07 15.1271C16.18 15.4771 16.1 15.8671 15.82 16.1471L13.62 18.3471Z" fill="#F9F9F9"/>
                                </svg>
                                {settings.phone}
                            </a>
                        )
                    }
                    {
                        settings?.address && (
                            <a href="">
                                <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5196 0.519043H19.4309C8.69952 0.519043 0 9.22112 0 19.9557V20.0444C0 30.779 8.69952 39.481 19.4309 39.481H19.5196C30.251 39.481 38.9505 30.779 38.9505 20.0444V19.9557C38.9505 9.22112 30.251 0.519043 19.5196 0.519043Z" fill="#FF512F"/>
                                <g clip-path="url(#clip0_7_346)">
                                <path d="M19.5 10C22.0196 10 24.4359 10.9404 26.2175 12.6142C27.9991 14.2881 29 16.5583 29 18.9255C29 21.9741 27.2309 24.4692 25.3668 26.2593C24.4354 27.144 23.4192 27.946 22.331 28.6553L21.8813 28.9429L21.6702 29.0748L21.2723 29.3128L20.9176 29.5161L20.4785 29.7561C20.1804 29.9159 19.8432 30 19.5 30C19.1568 30 18.8196 29.9159 18.5215 29.7561L18.0824 29.5161L17.5335 29.1987L17.3308 29.0748L16.8981 28.804C15.7241 28.0578 14.6306 27.2055 13.6332 26.2593C11.7691 24.4682 10 21.9741 10 18.9255C10 16.5583 11.0009 14.2881 12.7825 12.6142C14.5641 10.9404 16.9804 10 19.5 10ZM19.5 15.9503C19.0841 15.9503 18.6724 16.0273 18.2882 16.1768C17.904 16.3263 17.5549 16.5455 17.2608 16.8217C16.9668 17.098 16.7335 17.426 16.5744 17.787C16.4152 18.1479 16.3333 18.5348 16.3333 18.9255C16.3333 19.3162 16.4152 19.7031 16.5744 20.064C16.7335 20.425 16.9668 20.753 17.2608 21.0293C17.5549 21.3055 17.904 21.5247 18.2882 21.6742C18.6724 21.8237 19.0841 21.9007 19.5 21.9007C20.3399 21.9007 21.1453 21.5872 21.7392 21.0293C22.333 20.4713 22.6667 19.7146 22.6667 18.9255C22.6667 18.1364 22.333 17.3797 21.7392 16.8217C21.1453 16.2638 20.3399 15.9503 19.5 15.9503Z" fill="#F9F9F9"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_7_346">
                                <rect width="24" height="24" fill="white" transform="translate(7 7.55713)"/>
                                </clipPath>
                                </defs>
                                </svg>
                                {settings?.address}
                            </a>
                        )
                    }
                    {
                        settings?.email && (
                            <a href={ "mailto:" + settings?.email }>
                                <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5196 0.480957H19.4309C8.69952 0.480957 0 9.18304 0 19.9176V20.0063C0 30.7409 8.69952 39.443 19.4309 39.443H19.5196C30.251 39.443 38.9505 30.7409 38.9505 20.0063V19.9176C38.9505 9.18304 30.251 0.480957 19.5196 0.480957Z" fill="#FF512F"/>
                                <path d="M10.3 29C9.6675 29 9.12623 28.7921 8.6762 28.3763C8.22617 27.9605 8.00077 27.4601 8 26.875V14.125C8 13.5406 8.2254 13.0405 8.6762 12.6247C9.127 12.209 9.66827 12.0007 10.3 12H28.7C29.3325 12 29.8741 12.2082 30.3249 12.6247C30.7757 13.0412 31.0008 13.5413 31 14.125V26.875C31 27.4594 30.775 27.9598 30.3249 28.3763C29.8749 28.7928 29.3333 29.0007 28.7 29H10.3ZM19.5 21.5625L28.7 16.25V14.125L19.5 19.4375L10.3 14.125V16.25L19.5 21.5625Z" fill="#F9F9F9"/>
                                </svg>
                                {settings?.email}
                            </a>
                        )
                    }
                    <div className="download">
                        <a href="">تحميل البروفايل </a>
                        أو
                        <a href="">تحميل الأعمال</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;